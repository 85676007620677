<template>
  <div>
    <v-card class="radius_intab custom_card pa-5">
      <div class="toolbar d-flex">
        <v-row>
          <v-col cols="2">
            <v-select
              v-model="selected_year"
              class="rounded-lg"
              placeholder="Pilih Tahun"
              dense
              item-text="name"
              item-value="idx"
              :items="getYears"
              hide-details="auto"
              outlined
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              class="rounded-lg"
              color="#00b4cc"
              dark
              min-width="10px"
              @click="fetchReports"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-skeleton-loader
        v-if="loading"
        type="table-tbody"
      />

      <div
        v-if="!loading && reports"
        class="my-5"
      >
        <v-data-table
          :headers="headers"
          :items="reports"
          class="elevation-0 color_txt"
          hide-default-footer
          :item-class="row_classes"
          :items-per-page="12"
        >
          <template #[`item.month`]="{ item }">
            {{ $monthTranslation(item.month) }}
          </template>
          <template #[`item.status`]="{ item }">
            <Dot :color="$getStatusColorHex(item.status)" />
          </template>
          <template #[`item.verification_status`]="{ item }">
            <p class="ma-0 grey--text">
              {{ $getVerificationStatusFormatted(item.verification_status) }}
            </p>
          </template>

          <template #[`item.meet_criteria`]="{ item }">
            <CriteriaBadge :valid="item.meet_criteria" />
          </template>

          <template #[`item.actions`]="{ item }">
            <div v-if="item.report_id">
              <v-btn
                small
                depressed
                :color="item.disable ? '#64C9DA' : '#59B5CC'"
                class="rounded-lg text-capitalize"
                min-width="10px"
                dark
                @click="toDetail(item)"
              >
                <v-icon class="mr-3">
                  mdi-magnify
                </v-icon>
                Lihat Isian Data
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
      <div class="px-2 mt-7">
        <ReportStatusLegend />
      </div>
    </v-card>
    <v-card
      v-if="viewDetail"
      id="view_detail"
      class="mt-5 rounded-max custom_card pa-5"
    >
      <detail-data-fasyankes
        :id="selectedItem"
        :isian-data="isianData"
        :general-data="generalData"
        :grafik-timbulan="grafikTimbulan"
        :created="created"
        :profile="profile"
      />
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DetailDataFasyankes from "../Detail/detailDataFasyankes.vue";
import ReportStatusLegend from "../../../components/Form/Legend/ReportStatus/index.vue";
import Dot from "../../../components/icons/Dot.vue";
import CriteriaBadge from "../../../components/icons/CriteriaBadge.vue";

export default {
  name: "ListDatafasyankes",
  components: { DetailDataFasyankes, ReportStatusLegend, Dot, CriteriaBadge },
  data() {
    return {
      loading: false,
      selected_year: Number(this.$date().format("YYYY")),
      headers: [
        {
          text: "Bulan",
          align: "start",
          value: "month",
          sortable: false,
          width: "20%",
        },
        {
          text: "Status Pengisian",
          align: "center",
          value: "status",
          sortable: false,
          width: "10%",
        },
        {
          text: "Status Konfirmasi",
          align: "start",
          value: "verification_status",
          sortable: false,
          width: "20%",
        },
        {
          text: "Standar Pengolahan Limbah",
          align: "start",
          value: "meet_criteria",
          sortable: false,
          width: "25%",
        },
        {
          text: "Aksi",
          sortable: false,
          value: "actions",
          width: "20%",
          align: "center",
        },
      ],
      // props detail
      viewDetail: false,
      generalData: null,
      isianData: null,
      configChart: null,
      profile: null,
      grafikTimbulan: null,
      created: null,
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.user.all_user,
      reports: (state) => state.report.users_waste_yearly_report,
    }),
    getYears() {
      let data = [];
      let year = parseInt(this.$date().format("YYYY"));
      for (let i = 2020; i <= year; i++) {
        data.push({
          name: i,
          idx: i,
        });
      }
      return data;
    },
  },
  mounted() {
    this.fetchReports();
  },
  methods: {
    fetchReports() {
      const request_params = {
        year: this.selected_year,
      };

      this.$store.dispatch("report/getUserWasteYearlyReports", request_params);
    },
    toDetail(item) {
      this.selectedItem = item.report_id;
      this.created = item.report_createTime;
      this.viewDetail = true;
      // TODO: remove this fetchDetail after full migration to getWasteReportById
      this.fetchDetail(item);
      this.$store.dispatch("report/getWasteManagementReportById", item.report_id);
      this.$store.dispatch("report/getUserYearlyWasteEmergence", {
        user_id: item.user_id,
        year: item.created_at.substring(0, 4),
      })
      this.getProfile(item);
      this.getIsian(item);
      setTimeout(() => {
        this.$vuetify.goTo("#view_detail");
      }, 300);
    },
    fetchDetail(item) {
      let data = {
        path: "detail-data-limbah-fasyankes",
        id: item.report_id,
      };
      this.$store
        .dispatch("data/detailData", data)
        .then((data) => {
          this.generalData = data;
          let srcData = data.data;
          if (srcData) {
            // SUM OF DOMESTIK
            let organik = srcData["Limbah Organik"].value
              ? parseFloat(srcData["Limbah Organik"].value)
              : 0;
            let nonorganik = srcData["Limbah Non-organik"].value
              ? parseFloat(srcData["Limbah Non-organik"].value)
              : 0;
            let domestik = organik + nonorganik;

            // SUM OF INFEKSIUS
            let limbah_infeksius = srcData["limbah Infeksius"].value
              ? parseFloat(srcData["limbah Infeksius"].value)
              : 0;
            let tajam = srcData["limbah Tajam"].value
              ? parseFloat(srcData["limbah Tajam"].value)
              : 0;
            let patologi = srcData["limbah Patologi"].value
              ? parseFloat(srcData["limbah Patologi"].value)
              : 0;
            let infeksius = limbah_infeksius + tajam + patologi;

            // SUM OF NON INFEKSIUS
            let farmasi = srcData["limbah Farmasi"].value
              ? parseFloat(srcData["limbah Farmasi"].value)
              : 0;
            let kimia = srcData["limbah Kimia"].value
              ? parseFloat(srcData["limbah Kimia"].value)
              : 0;
            let sitotoksik = srcData["limbah Sitotoksik"]
              ? parseFloat(srcData["limbah Sitotoksik"].value)
              : 0;
            let radioaktif = srcData["limbah Radioaktif"].value
              ? parseFloat(srcData["limbah Radioaktif"].value)
              : 0;
            let noninfeksius = farmasi + kimia + sitotoksik + radioaktif;

            let medis = infeksius + noninfeksius;
            let total = domestik + medis;
            this.generalData["domestik"] = domestik;
            this.generalData["infeksius"] = infeksius;
            this.generalData["noninfeksius"] = noninfeksius;
            this.generalData["medis"] = medis;
            this.generalData["total"] = total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProfile(item) {
      let id = item.report_id;
      this.$store.dispatch("data/profileHeader", id).then((data) => {
        if (data.header[0].nama_rs) {
          data["0"]["name"] = data.header[0].nama_rs;
        } else {
          data["0"]["name"] = data.header[0].nama_puskesmas;
        }
        this.profile = data["0"];
        this.getTimbulan({
          report_id: item.report_id,
          user_id: data.header[0].user_id,
          date: this.$date().format("YYYY-MM-DD"),
        });
      });
    },
    getTimbulan(data) {
      this.$store
        .dispatch("data/timbulanLimbahFasyankes", data)
        .then((data) => {
          let res = data.data;
          let dataChart = null;
          let key = [];
          let val = [];
          res.forEach((el) => {
            key.push(el.month_name);
            if (el.timbulan_limbah_medis == null) {
              val.push(0);
            } else {
              val.push(el.timbulan_limbah_medis);
            }
          });
          dataChart = {
            val: val,
            key: key,
          };
          this.grafikMaker(dataChart);
        });
    },
    grafikMaker(data) {
      let options = {
        chart: {
          id: "bar",
          toolbar: {
            show: false,
          },
        },
        // colors: ["#008448"],
        plotOptions: {
          bar: {
            horizontal: false,
            // borderRadius: 5,
            columnWidth: "55%",
            // endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 3,
          colors: ["transparent"],
        },
        xaxis: {
          categories: data.key,
        },
        fill: {
          opacity: 1,
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      };
      let series = [
        {
          name: "Timbulan Limbah",
          data: data.val,
        },
      ];
      this.grafikTimbulan = {
        series: series,
        options: options,
      };
    },
    getIsian(item) {
      let body = {
        report_id: item.report_id,
      };
      this.$store
        .dispatch("formulir/getFormulirFasyankes", body)
        .then((data) => {
          this.isianData = data.data.limbah_fasyankes;
        });
    },
    row_classes(item) {
      let idx = this.reports.indexOf(item);
      if (idx % 2 == 0) {
        return "strip_blue";
      }
    },
  },
};
</script>

<style scoped>
.v-btn__content {
  min-width: none !important;
  width: 30px !important;
}

.row__report-status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.round_paginate {
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}
</style> 