<template>
  <div>
    <v-card class="radius_intab custom_card pa-5">
      <div class="toolbar d-flex">
        <v-row>
          <v-col cols="2">
            <v-select
              v-model="selected_year"
              class="rounded-lg"
              placeholder="Pilih Tahun"
              dense
              item-text="name"
              item-value="idx"
              :items="getYears"
              hide-details="auto"
              outlined
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              class="rounded-lg"
              color="#00b4cc"
              dark
              min-width="10px"
              @click="fetchReports"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-skeleton-loader
        v-if="loading"
        type="table-tbody"
      />

      <div
        v-if="!loading && reports"
        class="my-5"
      >
        <v-data-table
          :headers="headers"
          :items="reports"
          class="elevation-0 color_txt"
          hide-default-footer
          :item-class="row_classes"
          :items-per-page="12"
        >
          <template #[`item.month`]="{ item }">
            {{ $monthTranslation(item.month) }}
          </template>
          <template #[`item.reportcol`]="{ item }">
            <Dot :color="$getStatusColorHex(item.reportcol)" />
          </template>
          <template #[`item.verification_status`]="{ item }">
            <p
              class="ma-0 grey--text"
            >
              {{ $getVerificationStatusFormatted(item.verification_status) }}
            </p>
          </template>

          <template #[`item.meet_criteria`]="{ item }">
            <CriteriaBadge :valid="item.meet_criteria" />
          </template>

          <template #[`item.actions`]="{ item }">
            <div v-if="item.report_id">
              <v-btn
                small
                depressed
                :color="item.disable ? '#64C9DA' : '#59B5CC'"
                class="rounded-lg text-capitalize"
                min-width="10px"
                dark
                @click="toDetail(item)"
              >
                <v-icon class="mr-3">
                  mdi-magnify
                </v-icon>
                Lihat Isian Data
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
      <div class="px-2 mt-7">
        <ReportStatusLegend />
      </div>
    </v-card>
    <v-card
      v-if="view_detail"
      id="view_detail"
      class="mt-5 rounded-max custom_card pa-5"
    >
      <IsianKesling
        :id="selected_report_id"
        :isian-data="isianData"
        :profile="fasyankes_profile"
        :created="created"
        :report_status="selected_report_status"
        :report="selected_report"
      />
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IsianKesling from "../Detail/isianFormulir/isianKesling.vue";
import Dot from "../../../components/icons/Dot.vue";
import CriteriaBadge from "../../../components/icons/CriteriaBadge.vue";
import ReportStatusLegend from "../../../components/Form/Legend/ReportStatus/index.vue";

export default {
  name: "DataKesling",
  components: {
    IsianKesling,
    Dot,
    CriteriaBadge,
    ReportStatusLegend,
  },
  data() {
    return {
      selected_report_id: null,
      loading: false,
      selected_year: Number(this.$date().format("YYYY")),
      headers: [
        {
          text: "Bulan",
          align: "start",
          value: "month",
          sortable: false,
          width: "20%",
        },
        {
          text: "Status Pengisian",
          align: "center",
          value: "reportcol",
          sortable: false,
          width: "10%",
        },
        {
          text: "Status Konfirmasi",
          align: "start",
          value: "verification_status",
          sortable: false,
          width: "20%",
        },
        {
          text: "Kesehatan Lingkungan Kunci",
          align: "left",
          value: "meet_criteria",
          sortable: false,
          width: "25%",
        },
        {
          text: "Aksi",
          align: "center",
          sortable: false,
          value: "actions",
          width: "20%",
        },
      ],
      view_detail: false,
      generalData: null,
      isianData: null,
      isianDataKeslingBiasa: null,
      configChart: null,
      profile: null,
      created: null,
      fasyankes_profile: null,
      selected_report: null,
      selected_report_status: null,
    };
  },
  computed: {
    ...mapState({
      reports: (state) => state.report.user_yearly_report,
    }),
    getYears() {
      let years = [];
      let current_year = parseInt(this.$date().format("YYYY"));
      for (let i = 2020; i <= current_year; i++) {
        years.push({
          name: i,
          idx: i,
        });
      }
      return years;
    },
  },
  async mounted() {
    await this.fetchReports();
  },
  methods: {
    refetch() {
      this.fetchReports();
    },
    changeOption() {
      this.view_detail = false;
      this.fetchReports();
    },
    async fetchReports() {
      const request_params = {
        year: this.selected_year,
      };
      await this.$store.dispatch("report/getUserYearlyReport", request_params);
    },
    toDetail(item) {
      this.selected_report_id = item.report_id;
      this.$store.dispatch("report/getReportById", this.selected_report_id);
      this.created = item.report_createTime;
      this.view_detail = true;
      this.getProfile(item);
      this.getIsian(item);
      setTimeout(() => {
        this.$vuetify.goTo("#view_detail");
      }, 300);
    },
    getProfile(item) {
      let id = item.report_id;
      this.$store.dispatch("data/profileHeader", id).then((data) => {
        if (data.header[0].nama_rs) {
          data["0"]["name"] = data.header[0].nama_rs;
        } else {
          data["0"]["name"] = data.header[0].nama_puskesmas;
        }
        this.fasyankes_profile = data["0"];
      });
    },
    getIsian(item) {
      let body = {
        report_id: item.report_id,
      };

      this.$store.dispatch("formulir/getPertanyaanKunci", body).then((res) => {
        this.isianData = res.data.Kesehatan_Lingkungan;
      });
    },
    row_classes(item) {
      let idx = this.reports.indexOf(item);
      if (idx % 2 == 0) {
        return "strip_blue";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: "Montserrat";
  /* font-weight: 400 !important;
  font-size: 12px !important; */
  letter-spacing: -0.2px;
}

.v-btn__content {
  min-width: none !important;
  width: 30px !important;
}

.round_paginate {
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}

row__verification-status {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  line-height: 148.4%;
  text-align: justify;

  color: #586479;
}
</style>
