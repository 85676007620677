<template>
  <div class="padd_content">
    <div class="mb-3 d-flex pa-2 align-center">
      <blue-circle class="mr-2" />
      <h3 class="color_default">
        Tabel
      </h3>
    </div>

    <v-tabs
      v-model="selected_tab"
      background-color="#cdf0f7"
      :centered="false"
      hide-slider
      class="pb-2"
    >
      <v-tab
        href="#environmental-health-key"
        value="environmental-health-key"
        class="mr-2 text-capitalize header_data"
        active-class="active_tab"
      >
        <p class="ma-0">
          Kesling Kunci
        </p>
      </v-tab>
      <v-tab
        href="#waste"
        value="waste"
        class="mr-2 text-capitalize header_data"
        active-class="active_tab"
      >
        <p class="ma-0">
          Kelola Limbah
        </p>
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="selected_tab"
      style="background: none"
    >
      <v-tab-item value="environmental-health-key">
        <data-kesling />
        <div style="height: 100px" />
      </v-tab-item>
      <v-tab-item value="waste">
        <data-fasyankes />
        <div style="height: 100px" />
      </v-tab-item>
    </v-tabs-items>
    <div class="pa-2" />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";
import DataFasyankes from "../../components/Data/Fasyankes/dataFasyankes.vue";
import DataKesling from "../../components/Data/Fasyankes/dataKesling.vue";
import BlueCircle from "../../components/General/BlueCircle";

export default {
  name: "ListData",
  components: {
    BlueCircle,
    DataFasyankes,
    DataKesling,
  },
  data() {
    return {
      selected_tab: "environmental-health-key",
      updateLoginStatus: false,
      loginActive: true,
      dataAuth: JSON.parse(Cookies.get("dataAuth")),
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.user.all_user,
      role: (state) => state.role,
    }),
  },
  watch: {
    selected_tab() {
      this.$store.dispatch(
        "globalUI/setPageTableSelectedTab",
        this.selected_tab
      );
    },
  },
  methods: {
    switchStatusLogin() {
      this.updateLoginStatus = true;
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Montserrat";
}

.padd_content {
  padding: 40px 50px;
}
/* ListUser */
.header_data {
  color: #00b4cc !important;
  background: #fff;
  width: calc(100% / 5);
  border-radius: 13px 13px 0 0;

  font-weight: 600;
}
.active_tab {
  background: #00b4cc;
  color: #fff !important;
  box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
}
</style>